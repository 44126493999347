import React, { Component } from 'react';

import moment from 'moment'
import Server from '../../util/Server'
import Session from '../../hoc/Session'
import { Link } from 'react-router-dom'
import Loader from '../../components/Loader/Loader'
import './Customer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faChartBar, faEdit } from '@fortawesome/free-solid-svg-icons'

class Customer extends Component {
  state = {
    customers: []
  };

  async componentDidMount() {
    let response = await Server.get('/api/customer?userID='+ Session.getUser()._id);
    this.setState({customers: response.data});
  }

  renderCustomers(customers) {
    return customers.map((item)=> {
      return (<tr key={item._id}>
        <td>{item.name}</td>
        <td>{item.owner}</td>
        <td>{item.count}</td>
        <td>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
        <td className="actions">
          <Link to={`/campaigns/${item._id}`} className="btn btn-info">
              <FontAwesomeIcon icon={faEdit} />  
          </Link> &nbsp;
          <Link to={`/campaigns/log?customerID=${item._id}`} className="btn btn-info">
              <FontAwesomeIcon icon={faChartBar} />  
          </Link> &nbsp;
        </td>
      </tr>)
    });
  }

  render() {
    return (
      <div className="customer">
        <div>
          <h3 className="header">Clientes</h3>
        </div>

        <table className="table striped centered">
          <thead>
            <tr>
                <th>Cliente</th>
                <th>Responsável</th>
                <th>Carteira de Campanhas</th>
                <th>Última Atualização</th>
                <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.customers.length === 0 &&
            <tr>
              <td style={{textAlign: 'center'}} colSpan="7"><Loader /></td>
            </tr>}
            
            {this.renderCustomers(this.state.customers)}
          </tbody>
        </table>
      </div>
    )
  }
}

export default Customer;