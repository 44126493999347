import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faChartBar, faEdit } from '@fortawesome/free-solid-svg-icons'

const getMidiaName = (cod) => {
  switch(cod) {
    case '641':
      return 'Facebook Montadora';
    default:
      return 'Facebook';
  }
}

const CampaignItem = ({item, onChangeStatus, onDeleteItem, onOpenEditItem}) => {
    return (<tr key={item._id}>
      <td>{item.campaign} <br /> <b>UTM:</b> {item.utm_campaign}</td>
      <td style={{textTransform: 'capitalize'}}>{item.storeName ? item.storeName.toLowerCase() : item.storeName}</td>
      <td>
          <b>Dt. Cadastro:</b> {moment(item.createdAt).format('DD/MM/YYYY')} <br />
          <b>Val. Token:</b> {moment(item.expires).format('DD/MM/YYYY')}
      </td>
      <td>{getMidiaName(item.midia)}</td>
      <td>{item.isZeroKM ? 'Sim' : 'Não'}</td>
      <td>
        <div className="switch">
          <label>
            <input type="checkbox" defaultChecked={item.status} checked={item.status} onChange={() => onChangeStatus(item._id)} />
          </label>
        </div>
    </td>
      <td className="actions">
        <button onClick={(() => onOpenEditItem(item._id))} className="btn btn-info">
            <FontAwesomeIcon icon={faEdit} />
        </button>
         &nbsp;
        <Link to={`/campaigns/log?campaignID=${item._id}`} className="btn btn-info">
            <FontAwesomeIcon icon={faChartBar} />
        </Link> &nbsp;
        <button onClick={(() => onDeleteItem(item._id))} className="btn btn-danger">
            <FontAwesomeIcon icon={faTrash} />
        </button>
      </td>
    </tr>)
}

export default CampaignItem;