const keys = {
  USER_SESSION: 'USER_SESSION',
  STORE_SESSION: 'STORE_SESSION',
  TIPOLEAD_SESSION: 'TIPOLEAD_SESSION'
}

const session =  {
  isAutenticated: () => {
    return !!localStorage.getItem(keys.USER_SESSION)
  },

  getUser: () => {
    return JSON.parse(localStorage.getItem(keys.USER_SESSION));
  },

  setUser: (user) => {
    localStorage.setItem(keys.USER_SESSION, JSON.stringify(user));
  },
  
  getStores: () => {
    return JSON.parse(localStorage.getItem(keys.STORE_SESSION));
  },

  setStores: (stores) => {
    localStorage.setItem(keys.STORE_SESSION, JSON.stringify(stores));
  },
  
  getTipoLead: () => {
    return JSON.parse(localStorage.getItem(keys.TIPOLEAD_SESSION));
  },

  setTipoLead: (tipoLeads) => {
    localStorage.setItem(keys.TIPOLEAD_SESSION, JSON.stringify(tipoLeads));
  },

  sigOut: () => {
    localStorage.removeItem(keys.USER_SESSION);
  }
}

export default session;