import axios from 'axios'

export default {
  init: () => {
    return new Promise((resolve, reject) => {
      if (typeof window.FB !== 'undefined') {
        resolve();
      } else {
        window.fbAsyncInit = () => {
          window.FB.init({
            appId: '861082341171958',
            cookie: true,
            xfbml: true,
            version: 'v2.5'
          });

          resolve();
        };
        (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = "//connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      }
    });
  },
  checkLoginState: () => {
    return new Promise((resolve, reject) => {
      window.FB.getLoginStatus((response) => {
        response.status === 'connected' ? resolve(response) : reject(response);
      });
    });
  },
  login: () => {
    return new Promise((resolve, reject) => {
      window.FB.login((response) => {
        response.status === 'connected' ? resolve(response) : reject(response);
      }, {
        fields: "name,email,picture",
        scope: 'pages_show_list,leads_retrieval,pages_manage_ads,public_profile,pages_manage_metadata,pages_read_engagement',
      });
    });
  },
  logout: () => {
    return new Promise((resolve, reject) => {
      window.FB.logout((response) => {
        resolve();
      });
    });
  },
  fetchLongUserToken: (token) => {
    return axios.get(`https://graph.facebook.com/oauth/access_token?grant_type=fb_exchange_token&client_id=861082341171958&client_secret=9ce8d0a034d4dc8be628e853d05138a4&fb_exchange_token=${token}`)
  },
  fetchAccounts: (userID, access_token, nextPages) => {
    return new Promise((resolve, reject) => {
      window.FB.api(nextPages ? nextPages :
        `${userID}/accounts?limit=10&access_token=${access_token}`,
        response => response.error ? reject(response) : resolve(response)
      );
    });
  },
  fetchForms: (pageID, pageToken, next) => {
    return new Promise((resolve, reject) => {
      window.FB.api(next ? next :
        `${pageID}/leadgen_forms?filtering=[{field:"status",operator:"IN",value:['ACTIVE']}]&fields=name,id,status,created_time,questions&access_token=${pageToken}&limit=10`,
        response => response.error ? reject(response) : resolve(response)
      );
    });
  },
  fetch: () => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        '/me',
        { fields: 'first_name, last_name, gender' },
        response => response.error ? reject(response) : resolve(response)
      );
    });
  }
}