import axios from 'axios';

const api = {
  homol: 'http://localhost:8001',
  prod: 'https://api-facebook.mrsales.com.br'
}

const Server = axios.create({
  baseURL: api.prod
});

export default Server;