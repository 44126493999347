import React from 'react';

import { Route, Switch } from 'react-router-dom'
import LoginFacebook from './components/Login/LoginFacebook'
import Layout from './hoc/Layout/Layout'
import { ToastProvider } from 'react-toast-notifications';

const App = () => {
  return (
    <ToastProvider>
      <Switch>
        <Route path="/login" component={LoginFacebook} />
        <Route path="/" component={Layout} />
      </Switch>
    </ToastProvider>
  );
}

export default App;
