import React, { Component  } from 'react'
import '../Campaign.css';
import Loader from '../../../components/Loader/Loader'
import Server from '../../../util/Server'
import moment from 'moment'

class CampaignLog extends Component {
  state = {
    logs: []
  }

  renderCampaigns(logs) {
    return logs.map((item)=> {
      return (<tr key={item.date}>
            <td>{moment(item.date).format('DD/MM/YYYY')}</td>
            <td>{item.count}</td>
      </tr>)
      
    });
  } 

  async componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const customerID = params.get('customerID');
    const campaignID = params.get('campaignID');
    
    var docs = null;

    if(customerID) {
      docs = await Server.get('/api/report/customer?id='+customerID);
    }
    else {
      docs = await Server.get('/api/report/campaign?id='+campaignID);
    }
    
    let logs = docs.data.map(({_id, count})=> {
      return {
        count: count,
        date: moment(`${_id.year}-${_id.month}-${_id.day}`)
      }
    });

    logs.sort(function(a,b){
      return b.date.toDate() - a.date.toDate();
    });

    this.setState({logs})
  }

  render() {
    let totalLeads = 0;
    this.state.logs.forEach((item)=> {
      totalLeads+= item.count
    });

    return (<div className="campaign">
          <div>
            <h3 className="header">Log de Leads</h3>
            <blockquote>
              Total de dias: {this.state.logs.length} | Total de Leads  {totalLeads}
            </blockquote>
          </div>
          <div className="scroll">
            <table className="striped table">
              <thead>
                <tr>
                    <th>Dia</th>
                    <th>Qtd</th>
                </tr>
              </thead>
              <tbody>
                {this.state.logs.length === 0 &&
                  <tr>
                    <td style={{textAlign: 'center'}} colSpan="3"><Loader /></td>
                  </tr>
                }
                {this.renderCampaigns(this.state.logs)}
              </tbody>
            </table>
          </div>
        </div>)
  }
}

export default CampaignLog;