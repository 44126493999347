import React, { Component } from 'react'
//import Session from '../../../util/Session'
import Server from '../../../util/Server'
import Select from 'react-select';
import { withToastManager } from 'react-toast-notifications';
import moment from 'moment'
import '../Campaign.css'
import Loader from '../../../components/Loader/Loader'
import { Link } from 'react-router-dom'

class CampaignNew extends Component {

  state = {
    facebookPages: [],
    facebookForms: [],
    facebookFormsFiltered: [],
    search: '',
    stores: [],
    tipoLead: [],
    isLoading: false,
    tipoCampo: 'midia',
    pageID: null,
    info: null,
    midias: [{ value: 4, label: 'Facebook' },
    { value: 641, label: 'Facebook Montadora' }]
  };

  onCreateCompany = (e) => {

    const { match: { params } } = this.props;

    let facebookForms = [...this.state.facebookForms];

    facebookForms.forEach(async (form) => {
      let store = this.state.stores.find(el => el.LojaID === form.storeID);
      if (store) {
        let response = await Server.post('api/config', {
          "customer": params.campaignId,
          "storeID": store.LojaID,
          "midia": 4,//form.midia === 0 ? 4 : form.midia,
          "tipoLeadID": form.tipoLeadID,
          "storeName": store.NomeFantasia,
          "token": form.token,
          "campaign": form.campaign,
          "formID": form.formID,
          "pageID": form.pageID,
          "status": 0,
          "limit": 30,
          "isZeroKM": !!form.isZeroKM,
          "utm_source": "Facebook",
          "utm_campaign": form.utm_campaign || '',
          "startDate": moment(),
          "expires": moment().add(1, 'M')
        });

        if (response.data.success) {
          this.props.toastManager.add('Campanha cadastrada com sucesso', { appearance: 'success', autoDismiss: true });

          let itens = [...this.state.facebookForms];
          this.setState({ facebookForms: itens.filter(x => x.formID !== form.formID) })
          this.handleSearch(this.state.search)
        }
        else {
          this.props.toastManager.add('Não foi possível cadastrar a campanha, tente novamente!', { appearance: 'error', autoDismiss: true });
        }
      }
    });
  }

  onChangeState(id, field, value) {
    let forms = [...this.state.facebookForms];
    let index = forms.findIndex(x => x.formID === id);

    if (index > -1) {
      forms[index][field] = value;
      this.setState({ facebookForms: forms });
      this.handleSearch(this.state.search)
    }
  }

  handlePageChange = async (val) => {
    let forms = [];
    this.setState({ isLoading: true });
    if (val) {
      let page = this.state.facebookPages.find(el => el.id === val);
      var response = await Server.get(`/api/facebook/forms?token=${page.token}&id=${page.id}`);
      forms = response.data;
    }

    this.setState({ pageID: val, facebookForms: forms, facebookFormsFiltered: forms, isLoading: false });
  }

  handeMidiaDefault = (el) => {
    let facebookForms = [...this.state.facebookForms];
    facebookForms = facebookForms.map(item => {
      item.midia = el.value;
      return item;
    });

    this.setState({ facebookForms })
    this.handleSearch(this.state.search)
  }

  handeCampaignDefault = (val) => {
    let facebookForms = [...this.state.facebookForms];
    facebookForms = facebookForms.map(item => {
      item.utm_campaign = val;
      return item;
    });

    this.setState({ facebookForms })
    this.handleSearch(this.state.search)
  }

  handleSearch = (value) => {
    let items = [...this.state.facebookForms];
    this.setState({ facebookFormsFiltered: items.filter(x => x.utm_campaign.indexOf(value) > -1 || x.campaign.indexOf(value) > -1) })
    this.setState({ search: value });
  }

  async componentDidMount() {
    var response  = await Server.get(`/api/facebook/pages?id=`+ this.props.match.params.campaignId);
    var stores    = await Server.get(`/api/facebook/lojas?id=`+ this.props.match.params.campaignId);
    //var tipoLead  = Session.getTipoLead();

    this.setState({facebookPages: response.data, stores: stores.data, tipoLead: []});
  }

  renderForms(forms) { 
    let storeOptions = this.state.stores.map((store) => {
      return { value: store.LojaID, label: store.NomeFantasia };
    });

    return forms.map((item) => {
      return (<tr key={item.formID}>
        <td style={{ flexWrap: 'wrap', fontSize: 14, width: '30%' }}>{item.campaign}
        <br /><b>FormID:</b> {item.formID}
        </td>
        <td style={{ width: '30%' }}>
          <Select
            onChange={(el) => this.onChangeState(item.formID, 'storeID', el.value)}
            options={storeOptions}
            placeholder="selecione uma loja" />
        </td>
        {/* <td style={{ width: '20%' }}>
          {this.state.tipoCampo === 'midia' && <select
            style={{ display: 'block' }}
            className="form-control"
            onChange={(el) => this.onChangeState(item.formID, 'midia', el.target.value)}>
            <option value="">selecione à midia</option>
            {this.state.midias.map((el) => <option key={el.value} value={el.value}>{el.label}</option>)}
          </select>}

          {this.state.tipoCampo === 'tipolead' && <select
            style={{ display: 'block' }}
            className="form-control"
            onChange={(el) => this.onChangeState(item.formID, 'tipoLeadID', el.target.value)}>
            <option value="">selecione um tipo</option>
            {this.state.tipoLead.map((el) => <option key={el.TipoLeadID} value={el.TipoLeadID}>{el.Descricao}</option>)}
          </select>}
        </td> */}
        <td style={{ width: '5%' }}>
          <input type="checkbox" onChange={(el) => this.onChangeState(item.formID, 'isZeroKM', el.target.value)} />
        </td>
        <td style={{ width: '15%' }}>
          <input className="form-control"
            onChange={(el) => this.onChangeState(item.formID, 'utm_campaign', el.target.value)}
            placeholder="ex. feirão, lançamento" />
        </td>
      </tr>)
    });
  }

  render() {
    let pagesOptions = this.state.facebookPages.map((page) => {
      return { value: page.id, label: page.name };
    });

    return (<div className="campaign">
      <div>
        <h3 className="header">Cadastro de Campanha</h3>
        <blockquote>Preencha as informações a baixo para vincular uma campanha no Mr. Sales</blockquote>
      </div>
      <div className="">
        <div className="form-row">
          <div className="col-4">
            <Select className="browser-default"
              autoFocus={true}
              options={pagesOptions}
              onChange={(el) => this.handlePageChange(el.value)}
              placeholder="selecione uma página"
            />
          </div>
          {/* <div className="col-3">
            <select className="form-control" defaultValue={this.state.tipoCampo} onChange={(el) => this.setState({ tipoCampo: el.target.value })} style={{ display: 'block' }}>
              <option value="midia">Cadastrar por Mídia</option>
              <option value="tipolead">Cadastrar por Tipo de Lead</option>
            </select>
          </div> */}
          <div className="col-3">
            <input name="utm_campaign" className="form-control"
              onChange={(el) => this.handleSearch(el.target.value)}
              autoComplete="false" placeholder="pesquisar...." />
          </div>
          <div className="col-3">
            <button onClick={(el) => this.onCreateCompany(el)} className="btn-primary btn">
              Salvar Informações
          </button>
          &nbsp;
          <Link to={`/campaigns/${this.props.match.params.campaignId}`} className="btn-info btn">
              Voltar
            </Link>
          </div>
        </div>
      </div>
      <br />
      <div className="scrollMin">
        <table className="table striped">
          <thead>
            <tr>
              <th>Formulário</th>
              <th>Loja</th>
              {/* <th>{this.state.tipoCampo === 'midia' ? 'Midia' : 'Tipo Lead'}</th> */}
              <th>0Km</th>
              <th>UTM</th>
            </tr>
          </thead>
          <tbody>
            {this.state.isLoading &&
              <tr>
                <td style={{ textAlign: 'center' }} colSpan="5"><Loader /></td>
              </tr>}

            {this.renderForms(this.state.facebookFormsFiltered)}
          </tbody>
        </table>
      </div>
    </div>)
  }
}

export default withToastManager(CampaignNew);