import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import Session from '../../../hoc/Session'
import { Link, withRouter } from 'react-router-dom';

const sigOut = history => () => {
  Session.sigOut()
  history.push('/login')
}

const navbar = ({history}) => {
  return (
    <div className="">
      <ul className="navbar-nav">
        <li className="nav-item"><Link to="/campaigns"><FontAwesomeIcon icon={faUser} /></Link></li>
        <li className="nav-item"><a href="javascript:void(0)" onClick={sigOut(history)}><FontAwesomeIcon icon={faSignOutAlt} /></a></li>
      </ul>
    </div>
  )
};

export default withRouter(navbar);