import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

const campaignEdit = ({onCreateCompany, onChangeStateEdit, onSaveEditItem, closeModal, item}) => {
    return (
      <div className="container campaign">
      <div className="row">
        <div className="col col12">
        <div className="row">
          <div className="col">
            <h3 className="header">Atualizar Informações</h3>
          </div>
          <br />
        </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                <label>Formúlario</label>
                <input className="form-control" disabled value={item.campaign} />
              </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                <label>Dt. Cadastro</label>
                <input className="form-control" disabled value={moment(item.startDate).format('DD/MM/YYYY')} />
              </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                <label>Val. Token</label>
                <input className="form-control" disabled value={moment(item.expires).format('DD/MM/YYYY')} />
              </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label>UTM</label>
                  <input className="form-control" value={item.utm_campaign} 
                    onChange={(el) => onChangeStateEdit('utm_campaign', el.target.value)} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group form-check">
                  <input defaultChecked={item.status} 
                    checked={item.status}
                    onChange={(el) => onChangeStateEdit('status', el.target.checked)}
                  type="checkbox" className="form-check-input" />
                  <label className="form-check-label">Ativo</label>
                </div>
              </div>

              <div className="col">
                <div className="form-group form-check">
                  <input defaultChecked={item.isZeroKM} 
                    checked={item.isZeroKM}
                    onChange={(el) => onChangeStateEdit('isZeroKM', el.target.checked)}
                  type="checkbox" className="form-check-input" />
                  <label className="form-check-label">0KM</label>
                </div>
              </div>
            </div>
            
            <div className="row">
                <div className="text-right col">
                  <button onClick={()=> onSaveEditItem()} type="button" className="btn btn-info"><FontAwesomeIcon icon={faCheck} /> Atualizar informações</button>
                  &nbsp;&nbsp;
                  <button onClick={()=> closeModal()} type="button" className="btn btn-danger"><FontAwesomeIcon icon={faTimes} /> Cancelar</button>
                </div>
            </div>
          
        </div>
      </div>
    </div>
  )
}


export default campaignEdit;