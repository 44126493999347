import React, { useEffect, useState } from 'react'
import Toolbar from '../Toolbar/Toolbar'
import Session from '../../hoc/Session'
import { Redirect } from 'react-router-dom'
import Server from '../../util/Server'
import ServerMrSales from '../../util/ServerMrSales'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import FacebookAPI from '../../util/facebook';

import './Login.css';

export default function LoginFacebook(props) {

  const saveSession = async ({ userID }) => {
    let { data } = await Server.get('/api/user?userID=' + userID);

    if (data && data.customers.length > 0) {
      Session.setUser(data);

      let lojas = await ServerMrSales.get('/parceiro/lojas', {
        headers: {
          "Authorization": data.mrSalesToken
        }
      });

      let tipoLeads = await ServerMrSales.get(`/parceiro/tipolead/${data.mrSalesContaID}`,
        {
          headers: { "Authorization": data.mrSalesToken }
        });

      lojas = lojas.data.Data.map(({ LojaID, NomeFantasia }) => {
        return { LojaID: LojaID, NomeFantasia: NomeFantasia };
      });

      Session.setStores(lojas);
      Session.setTipoLead(tipoLeads.data.Data);
      props.history.push('/');
    }
    else {
      this.setState({ error: true });
    }
  }

  async function load() {
    await FacebookAPI.init();
    try {
      const session = await fetchFbSession();
      await saveSession(session);
    } catch (error) {
      //setIsLoaded(true);
    }
  }

  useEffect(() => {
    document.title = 'Mr. Sales - Facebook Lead Ads';
    load();
  }, [])

  const fetchFbSession = async () => {
    let { authResponse: { userID, accessToken }, status } = await FacebookAPI.checkLoginState();
    if (userID) {
      let { data } = await FacebookAPI.fetchLongUserToken(accessToken);
      return { userID, long_token: data.access_token, status };
    }
  }

  const auth = async () => {
    try {
      await FacebookAPI.login();
      const session = await fetchFbSession();
      await saveSession(session);
    } catch (error) {
      //setIsLoaded(true);
    }
  }

  if (Session.isAutenticated()) {
    return <Redirect to="/" />
  }

  return (
    <>
      <Toolbar />
      <div className="container login">
        <div className="col s12">
          <div className="row">
            <div className="col s12 l2"><p></p></div>

            <div className="col s12 l8">
              <div className="row">
                <button onClick={auth} style={{ marginTop: 100 }} className="btn btn-primary send" type="button">
                  Login com Facebook <FontAwesomeIcon icon={faFacebook} />
                </button>
              </div>

              {/* {this.state.error && <div className="col s12 center">
                <p style={{color: 'red'}}>usuário sem clientes associados, entre em contato com o administrador</p>
              </div>} */}
            </div>

            <div className="col s12 l2"><p></p></div>
          </div>
        </div>
      </div>
    </>
  )
}
/*
class LoginFacebook extends Component {
  
  state = {
    showLogin: false,
    error: false
  }

  async componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const isAuthenticated = params.get('isAuthenticated');
    const userID = params.get('userID');
    const { history } = this.props;
    
    if(isAuthenticated) {
       let { data } = await Server.get('/api/user?userID='+userID);

      if(data && data.customers.length > 0) {
        Session.setUser(data);

        let lojas = await ServerMrSales.get('/parceiro/lojas', {headers: {
          "Authorization": data.mrSalesToken
        }});

        let tipoLeads = await ServerMrSales.get(`/parceiro/tipolead/${data.mrSalesContaID}`, 
          { headers: {"Authorization": data.mrSalesToken }
        });

        lojas = lojas.data.Data.map(({LojaID, NomeFantasia}) => {
          return {LojaID: LojaID, NomeFantasia: NomeFantasia};
        });

        Session.setStores(lojas);
        Session.setTipoLead(tipoLeads.data.Data);
        history.push('/');
      }
      else {
        this.setState({error: true});
      }
    }
    else {
      this.setState({showLogin: true});
    }
  }

  onAuthenticated = () => {
    //window.location.assign('https://localhost:8001/auth/facebook');
    //window.location.assign('https://api-facebook.mrsales.com.br/auth/facebook');
  }

  render() {
    if(Session.isAutenticated()) {
      return <Redirect to="/" />
    }

    return (
      <>
        <Toolbar />
        <div className="container login">
          <div className="col s12">
            <div className="row">
              <div className="col s12 l2"><p></p></div>

              <div className="col s12 l8">
                <div className="row">
                  {this.state.showLogin &&<button onClick={()=> this.onAuthenticated()} style={{marginTop: 100}} className="btn btn-primary send" type="button">
                    Login com Facebook <FontAwesomeIcon icon={faFacebook} />
                  </button>}
                </div>

                {this.state.error && <div className="col s12 center">
                  <p style={{color: 'red'}}>usuário sem clientes associados, entre em contato com o administrador</p>
                </div>}
              </div>

              <div className="col s12 l2"><p></p></div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default LoginFacebook;
*/