import React from 'react';

import Session from '../Session'
import Toolbar from '../../components/Toolbar/Toolbar'
import { Route, Redirect, Switch } from 'react-router-dom'
import Customer from '../../container/Customer/Customer'
import Campaign from '../../container/Campaign/Campaign'

import CampaignLog from '../../container/Campaign/CampaignLog/CampaignLog'
import CampaignNew from '../../container/Campaign/CampaignNew/CampaignNew'


const layout = ({history}) => {
  
  // if(!Session.isAutenticated()) {
  //   return <Redirect to='/login' />;
  // }

  return (
    <>
      <Toolbar />
      <div className="container" style={{marginTop:90}}>
        <Switch>
          <Route path="/" component={Customer} exact />
          <Route path="/campaigns" component={Campaign} exact />
          <Route path="/campaigns/log" component={CampaignLog} exact />
          <Route path="/campaigns/:campaignId/new" component={CampaignNew} exact />
          <Route path="/campaigns/:campaignId" component={Campaign} exact />
        </Switch>
      </div>
    </>
  )
}

export default layout;