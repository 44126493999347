import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

import Navbar from './Navbar/Navbar';
import { Link } from 'react-router-dom';
import Session from '../../hoc/Session'
import './Toolbar.css';

const toolbar = (props) => {
  return (
    <nav className="navbar navbar-expand-md navbar-dark fixed-top">
      <div className="container">
        <Link to="/" className="brand-logo">
          <FontAwesomeIcon icon={faFacebook} /> | Mr. Sales Ads
        </Link>

        {Session.isAutenticated() && <Navbar />}
      </div>
    </nav>
  )
}

export default toolbar;