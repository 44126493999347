import React, { Component } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { Link } from 'react-router-dom'
import Server from '../../util/Server'
import CampaignList from '../../components/Campaign/CampaignList'
import './Campaign.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import bootbox from 'bootbox'
import Modal from 'react-modal';
import CampaignEdit from '../../components/Campaign/CampaignEdit/CampaignEdit'
//import Session from '../../util/Session'

Modal.setAppElement('#root')

const customStyles = {
  content : {
    left  : '20%',
    right : '20%',
    position: 'absolute',
    zIndex: '9999'
  }
};

class Campaign extends Component {
  state = {
    campaigns: [],
    campaignsFiltered: [],
    modalIsOpen: false,
    isLoading: false,
    search: '',
    campanyState: "1",
    limitCampaign: 0,
    campaign: null,
    campaignId: null
  };

  async componentDidMount() {
    const { match: { params } } = this.props;
    this.setState({isLoading: true})
    
    var response = await Server.get(`/api/config?customer=${params.campaignId}`);
    var customerResponse = await Server.get(`/api/customer/${params.campaignId}`);

    this.setState({campaigns: response.data, campaignsFiltered: response.data});
    this.handleSearch(this.state.search);
    this.setState({isLoading: false, limitCampaign: customerResponse.data.limitCampaign, campaignId: params.campaignId })
  }

  onDeleteItem = async (id) => {
    const { toastManager } = this.props;

    let campaigns = [...this.state.campaigns];
    let itemIndex = campaigns.findIndex(x=> x._id === id);

    if(itemIndex > -1) {
      bootbox.confirm('Tem certeza que deseja excluir essa campanha?', async (confirm) => {
        if(confirm) {
          var response = await Server.delete(`/api/config/`+id);

          if(response.data.success) {
            campaigns = campaigns.filter(x=> x._id !== id);

            this.setState({campaigns});
            this.handleSearch(this.state.search);
            toastManager.add(`Campanha excluida com sucesso`, { appearance: 'success', autoDismiss: true });
          }
          else {
            toastManager.add('não foi possível excluir a campanha', { appearance: 'error', autoDismiss: true });
          }
        }
      });
    }
  }

  onChangeStatus = async (id) => {
    const { toastManager } = this.props;
    
    let campaigns = [...this.state.campaigns];
    let itemIndex = campaigns.findIndex(x=> x._id === id);

    if(itemIndex > -1) {
      var response = await Server.post('/api/config/change', {
        "id": campaigns[itemIndex]._id,
        "status": !campaigns[itemIndex].status,
        "customerID": this.state.campaignId
      });

      if(response.data.success) {
        campaigns[itemIndex].status = !campaigns[itemIndex].status;
        this.setState({campaigns});
        this.handleSearch(this.state.search);
        toastManager.add(`Campanha ${campaigns[itemIndex].status ? 'ativada' : 'inativada'} com sucesso`, { appearance: 'success', autoDismiss: true });
      }
      else {
        toastManager.add(response.data.message, { appearance: 'error', autoDismiss: true });
      }
    }
  }

  onOpenEditItem = (id) => {
    let campaigns = [...this.state.campaigns];
    let campaign = campaigns.find(x=> x._id === id);

    this.setState({modalIsOpen: true, campaign});
  }

  onChangeStateEdit = (field, value) => {
    let campaign = {...this.state.campaign};
    
    campaign[field] = value;
    this.setState({campaign});
  }

  onSaveEditItem = async () => {
    const { toastManager } = this.props;

    let campaigns = [...this.state.campaigns];
    let campaign = {...this.state.campaign, customerID: this.state.campaignId};
    let itemIndex = campaigns.findIndex(x=> x._id === campaign._id);
    
    if(itemIndex > -1) {
      var response = await Server.put(`/api/config/`+campaign._id, campaign);

      if(response.data.success) {
        campaigns[itemIndex].utm_campaign = campaign.utm_campaign;
        campaigns[itemIndex].status = campaign.status;
        campaigns[itemIndex].isZeroKM = campaign.isZeroKM;

        this.setState({campaigns, modalIsOpen: false});
        this.setState({campaign: null});
        this.handleSearch(this.state.search);
        toastManager.add(`Campanha atualizada com sucesso`, { appearance: 'success', autoDismiss: true });
      }
      else {
        toastManager.add(response.data.message, { appearance: 'error', autoDismiss: true });
      }
    }
  }

  afterOpenModal = async () => { }

  closeModal = () => {
    this.setState({modalIsOpen: false});
  }

  handleSearch = (search =  null, campanyState = null) => {
    if(!search) {
      search = this.state.search; 
    }

    if(!campanyState) {
      campanyState = this.state.campanyState;
    }

    let status = campanyState === "1";

    let items = [...this.state.campaigns];
    this.setState({campaignsFiltered: items.filter(x=> 
      (x.utm_campaign.indexOf(search) > -1 || x.campaign.indexOf(search) > -1) && x.status === status)})
    this.setState({search, campanyState});
  }

  render() {
    return (
      <div className="campaign">
        <div className="bd-callout bd-callout-info">
          <b>Limite de Campanhas: {this.state.limitCampaign} | Total de Campanhas Ativas {this.state.campaigns.filter(r=> r.status).length}</b>
          <br /><br />
          <div className="form-row">
            {/* <div className="col-6">
              <input name="utm_campaign" className="form-control" 
                onChange={(el) => this.handleSearch(el.target.value)}
                autoComplete="false" id="utm_campaign" placeholder="pesquisar...." />
            </div> */}
            <div className="col-6">
              <select className="form-control" defaultValue={'1'} onChange={(el) => this.handleSearch(null, el.target.value) } style={{display:'block'}}>
                  <option value="1">Campanhas Ativas</option>
                  <option value="0">Campanhas Inativas</option>
              </select>
            </div>

            <div className="col-6">
              <Link to={`/campaigns/${this.props.match.params.campaignId}/new`} className="btn-info btn">
                  <FontAwesomeIcon icon={faPlus} /> Adicionar Campanha
              </Link>
              &nbsp;
            
            </div>
          </div>
        </div>

        <br />
        <div className="scroll">
          <CampaignList isLoading={this.state.isLoading} 
                      campaigns={this.state.campaignsFiltered} 
                      onChangeStatus={this.onChangeStatus}
                      onOpenEditItem={this.onOpenEditItem}
                      onDeleteItem={this.onDeleteItem} />
        </div>

        <Modal
          style={customStyles}
          isOpen={this.state.modalIsOpen} 
          onAfterOpen={this.afterOpenModal} 
          onRequestClose={() => this.closeModal(null)}>
            <CampaignEdit 
                item={this.state.campaign} 
                onChangeStateEdit={this.onChangeStateEdit}
                onSaveEditItem={this.onSaveEditItem}
                closeModal={this.closeModal} />
        </Modal>

      </div>
    )
  }
}

export default withToastManager(Campaign);