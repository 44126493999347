import React from 'react'
import Loader from '../Loader/Loader'
import CampaignItem from './CampaignItem/CampaignItem'

const CampaignList = ({campaigns, onChangeStatus, onDeleteItem, onOpenEditItem, isLoading}) => {
  let items = campaigns.map((item) => <CampaignItem key={item._id} 
                                        onDeleteItem={onDeleteItem} 
                                        onChangeStatus={onChangeStatus} 
                                        onOpenEditItem={onOpenEditItem} 
                                        item={item} />)
  return (<table className="table striped">
            <thead>
              <tr>
                <th>Campanha</th>
                <th>Loja</th>
                <th>Data</th>
                <th>Mídia</th>
                <th>0KM</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {isLoading &&
                <tr>
                  <td style={{textAlign: 'center'}} colSpan="7"><Loader /></td>
                </tr>
              }
              {items}
            </tbody>
          </table>)
}

export default CampaignList;